import styled from 'styled-components'

export type Color =
  | 'lightGray'
  | 'darkGray'
  | 'gold'
  | 'christmasGreen'
  | 'white'

const colors = {
  lightGray: '#ededed',
  darkGray: '#181818',
  gold: '#957655',
  christmasGreen: '#0F2710',
  white: '#ffffff',
}

const spacings = {
  vertical: '140px',
  horizontal: '10vw',
  horizontalMobile: '5vw',
}

const breakpointsDown = {
  mobile: '(max-width: 420px)',
  middle: '(max-width: 580px)',
  tablet: '(max-width: 740px)',
  desktop: '(max-width: 980px)',
  wide: '(max-width: 1300px)',
}

const fonts = {
  clearface: "'ITC-Clearface-STD', serif",
  apercu: "'apercu', sans-serif;",
}

const ProductDescription = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  color: ${colors.gold};
`

const ProductPrice = styled.div`
  font-size: 1.5rem;

  @media ${breakpointsDown.tablet} {
    font-size: 1.2rem;
  }
`

const InactiveProductPrice = styled.div`
  font-size: 1.5rem;
  color: gray;
  text-decoration: line-through;

  @media ${breakpointsDown.tablet} {
    font-size: 1.2rem;
  }
`

export {
  colors,
  spacings,
  fonts,
  breakpointsDown,
  ProductDescription,
  ProductPrice,
  InactiveProductPrice,
}
